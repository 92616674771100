import { waitAuth } from "~/utils/middleware";

import { Sub } from "~/types/subscriptions";

import { log } from "~/composables/log";

export default defineNuxtRouteMiddleware(async () => {
	await waitAuth(Sub.Type.Drive);

	const isCanceled = __subscription().getIsDisabled(Sub.Type.Drive);
	if (!__subscription().has(Sub.Type.Drive) && !isCanceled) {
		log("❌ Has no Drive");
		return navigateTo("/");
	}

	log("✅ Has Drive or Drive is canceled");
});
